import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { BANNER_HIGHT } from './constants'

export const StyledSponsorsItemsWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`

export const SponsorsItemWrapper = styled(Link)`
  border-radius: 8px;
  height: ${BANNER_HIGHT};
  max-width: 302px;
  min-width: 10px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  &:hover #embeddedSVG {
    transform: scale(1.15);
    transition: all 0.4s;
  }
`

export const SponsorsItemBG = styled.div`
  overflow: hidden;
  position: relative;
  white-space: nowrap;
`

export const SponsorsItemLogo = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  margin-left: 12px;
  position: absolute;
  top: 0;
  z-index: 10;
`

export const SponsorsItemAdditionalImage = styled.div<{ itemStyles }>`
  left: 0;
  position: absolute;
  ${({ itemStyles }) =>
    css`
      top: ${itemStyles?.topAdditionalImgPosition
        ? itemStyles?.topAdditionalImgPosition
        : 0};
    `}
  z-index: 9;
`

export const SponsorsItemImage = styled.div<{ itemStyles }>`
  position: absolute;
  right: 0;
  ${({ itemStyles }) =>
    css`
      right: ${itemStyles?.rightPosition ? itemStyles?.rightPosition : 0};
      top: ${itemStyles?.topPosition ? itemStyles?.topPosition : '10px'};
    `}
  z-index: 9;
`
