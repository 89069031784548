import React from 'react'

export const AviatrixBackground = () => (
  <svg
    fill="none"
    height="108"
    viewBox="0 0 101 108"
    width="101"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="url(#paint0_linear_29949_722530)"
      height="108"
      rx="8"
      width="100.429"
      x="0.571289"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_29949_722530"
        x1="0.571289"
        x2="117.338"
        y1="0"
        y2="24.3698"
      >
        <stop stopColor="#4C0588" />
        <stop offset="1" stopColor="#12082C" />
      </linearGradient>
    </defs>
  </svg>
)

export const AviatrixLogo = () => {
  return (
    <svg
      fill="none"
      height="16"
      id="embeddedLowerSVG"
      viewBox="0 0 60 16"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M52.9218 6.11857C52.95 6.13157 52.9773 6.14552 53.0035 6.16041C53.1468 6.23678 53.2795 6.33321 53.3981 6.44705C53.5853 6.62006 53.7337 6.83449 53.8316 7.07476C53.9133 7.27603 53.9582 7.49119 53.9653 7.70962V7.7321L57.5622 3.43629L59.8456 0.703886C60.0101 0.507276 60.0348 0.330801 59.9591 0.182751C59.9551 0.181551 59.9515 0.179427 59.9485 0.17655C59.9455 0.173673 59.9435 0.17012 59.942 0.166169C59.9349 0.149193 59.9258 0.132939 59.9157 0.117609C59.895 0.0904314 59.8713 0.0654427 59.8456 0.0429902C59.7997 0.0123549 59.7452 -0.00253861 59.6907 0.000353912H57.5021C57.3185 0.000238119 57.1358 0.0156861 56.9546 0.0465453C56.3547 0.274475 55.8177 0.653627 55.3918 1.15041C54.4962 2.22169 53.717 3.9694 53.173 5.40776L52.9218 6.11857ZM54.8353 2.59655C54.7288 2.85806 54.642 3.12771 54.5749 3.40313C54.5305 3.58908 54.4956 3.7774 54.4639 3.96809C54.4502 4.05921 54.4366 4.15056 54.424 4.2405C54.3927 4.5141 54.3655 4.78769 54.3518 5.06958C54.343 5.25479 54.3304 5.43639 54.3178 5.61675L54.3141 5.66906C54.3087 5.74552 54.3033 5.82241 54.2983 5.89867C54.2837 6.09173 54.262 6.28479 54.2363 6.47663C54.2322 6.50743 54.2277 6.53822 54.2227 6.56901C54.2206 6.58443 54.2181 6.5998 54.2156 6.61522C54.1727 6.91807 54.1116 7.21787 54.0334 7.51299C54.1914 7.23455 54.316 6.93707 54.4048 6.62706L55.4786 5.10511L56.0453 4.30801L54.5295 6.13078C54.5389 6.08194 54.5475 6.03307 54.5561 5.98419L54.5648 5.93535C54.586 5.81693 54.6031 5.6985 54.6178 5.58008L56.0352 3.55473L54.6667 5.1821C54.6677 5.17193 54.6692 5.16159 54.6707 5.15113C54.6733 5.13479 54.6758 5.11816 54.6758 5.10156L54.7177 4.66333L54.7257 4.67044L55.7314 3.20534L55.9444 2.89384L55.685 3.20415L54.7651 4.27721C54.7858 4.1035 54.8105 3.93058 54.8398 3.75845L55.9288 2.15359L54.9251 3.31311C54.97 3.10466 55.021 2.89857 55.0835 2.69604C55.2516 2.17467 55.4549 1.66646 55.6916 1.17528C55.3373 1.59966 55.0487 2.07917 54.8353 2.59655Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M20.0332 3.14632C19.9294 3.14632 19.8491 3.11177 19.7925 3.04267C19.7358 2.9637 19.7169 2.86992 19.7358 2.76134L20.019 1.38432C20.0379 1.27573 20.0898 1.18689 20.1748 1.11779C20.2692 1.03883 20.3636 0.999341 20.458 0.999341H22.2282C22.3321 0.999341 22.4123 1.03883 22.469 1.11779C22.5256 1.18689 22.5445 1.27573 22.5256 1.38432L22.2282 2.76134C22.2188 2.86992 22.1716 2.9637 22.0866 3.04267C22.0017 3.11177 21.9072 3.14632 21.8034 3.14632H20.0332Z"
        fill="white"
      />
      <path
        d="M18.0085 11.8033C18.0652 11.8724 18.1454 11.907 18.2493 11.907H20.0195C20.1233 11.907 20.213 11.8724 20.2885 11.8033C20.3735 11.7343 20.4254 11.6454 20.4443 11.5368L21.8605 4.57768C21.8793 4.46909 21.8605 4.38025 21.8038 4.31115C21.7566 4.24206 21.6811 4.20751 21.5772 4.20751H19.8071C19.7032 4.20751 19.6088 4.24699 19.5238 4.32596C19.4389 4.39506 19.3869 4.47896 19.3681 4.57768L17.966 11.5368C17.9472 11.6355 17.9613 11.7244 18.0085 11.8033Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M0.0708076 11.8364C0.118011 11.8857 0.184101 11.9104 0.269067 11.9104H2.20919C2.43578 11.9104 2.61516 11.8018 2.74733 11.5847L3.5687 10.1484H7.26484L7.49142 11.555C7.52921 11.792 7.67555 11.9104 7.93043 11.9104H9.72893C9.83278 11.9104 9.91776 11.871 9.98386 11.792C10.05 11.713 10.0783 11.6044 10.0688 11.4662L8.69518 1.9751C8.65738 1.68884 8.49691 1.54571 8.21367 1.54571H5.91949C5.64573 1.54571 5.4286 1.68884 5.26808 1.9751L0.0424858 11.4662C0.0141639 11.5254 0 11.5797 0 11.6291C0 11.7081 0.0236045 11.7772 0.0708076 11.8364ZM7.12325 7.9274H4.60249L6.62756 4.15169L7.12325 7.9274Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M12.2294 11.907C12.0972 11.907 12.0028 11.8774 11.9462 11.8182C11.899 11.7491 11.8612 11.6553 11.8329 11.5368L10.5442 4.6369C10.5253 4.50857 10.5442 4.40493 10.6008 4.32596C10.6669 4.24699 10.7708 4.20751 10.9124 4.20751H12.4985C12.6212 4.20751 12.7062 4.23712 12.7534 4.29635C12.81 4.35558 12.8525 4.43948 12.8808 4.54806L13.5181 8.94566L15.9822 4.51845C16.0955 4.31115 16.2513 4.20751 16.4495 4.20751H18.2622C18.3472 4.20751 18.4132 4.23219 18.4605 4.28154C18.5077 4.32103 18.5313 4.38025 18.5313 4.45922C18.5313 4.51845 18.5077 4.58755 18.4605 4.66652L14.3961 11.5368C14.3206 11.6553 14.2403 11.7491 14.1554 11.8182C14.0799 11.8774 13.976 11.907 13.8438 11.907H12.2294Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M22.6908 11.3921C23.125 11.8363 23.7387 12.0584 24.5317 12.0584C25.0321 12.0584 25.4475 11.9794 25.7779 11.8215C26.1084 11.6537 26.4388 11.4069 26.7693 11.0811L26.6701 11.5402C26.6512 11.6388 26.6701 11.7277 26.7268 11.8066C26.7834 11.8757 26.8637 11.9103 26.9675 11.9103H28.6527C28.7472 11.9103 28.8368 11.8757 28.9218 11.8066C29.0068 11.7376 29.0587 11.6487 29.0776 11.5402L30.4937 4.58098C30.5126 4.48227 30.4937 4.39836 30.4371 4.32926C30.3804 4.2503 30.3002 4.21081 30.1964 4.21081H28.5253C28.4214 4.21081 28.327 4.24536 28.242 4.31446C28.1665 4.38355 28.1193 4.4724 28.1004 4.58098L28.0155 4.95115C27.8172 4.66488 27.567 4.44772 27.2649 4.29965C26.9628 4.14171 26.5474 4.06274 26.0187 4.06274C25.0652 4.06274 24.2816 4.36875 23.6679 4.98076C23.0542 5.5829 22.6105 6.43183 22.3367 7.52749L22.1243 8.57879C22.0771 8.83543 22.0535 9.1316 22.0535 9.46719C22.0535 10.2964 22.2659 10.938 22.6908 11.3921ZM26.5852 9.60046C26.3302 9.86699 25.9951 10.0003 25.5797 10.0003C25.2587 10.0003 25.0321 9.91631 24.8999 9.74852C24.7678 9.58072 24.7017 9.33887 24.7017 9.02302C24.7017 8.86507 24.7158 8.69727 24.7441 8.51958C24.7725 8.24315 24.8339 7.93719 24.9283 7.60155C25.0604 7.13759 25.2445 6.77731 25.4806 6.52067C25.7166 6.25414 26.0376 6.12087 26.4435 6.12087C26.774 6.12087 27.0147 6.20971 27.1658 6.3874C27.3168 6.56509 27.3924 6.80694 27.3924 7.11295C27.3924 7.18201 27.3829 7.29554 27.3641 7.45349C27.3168 7.77924 27.2413 8.14941 27.1374 8.564C27.0336 8.9786 26.8495 9.32408 26.5852 9.60046Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M31.9366 9.8361C31.9366 11.218 32.8193 11.9091 34.5848 11.9091H35.7885C35.8829 11.9091 35.9726 11.8745 36.0576 11.8054C36.1425 11.7363 36.1945 11.6475 36.2134 11.5389L36.4966 10.1766C36.5155 10.0681 36.4966 9.97926 36.4399 9.91016C36.3927 9.83115 36.3172 9.79168 36.2134 9.79168H35.0663C34.8492 9.79168 34.6934 9.74726 34.599 9.65841C34.514 9.56957 34.4715 9.4314 34.4715 9.24382C34.4715 9.07602 34.4904 8.90823 34.5281 8.74043L35.0379 6.22325H36.8223C36.9167 6.22325 37.0064 6.18872 37.0914 6.11961C37.1763 6.0505 37.2283 5.96166 37.2471 5.85308L37.5021 4.57972C37.5209 4.47114 37.5021 4.3823 37.4454 4.3132C37.3982 4.2441 37.3227 4.20955 37.2188 4.20955H35.4486L35.9443 1.76645C35.9632 1.66773 35.9443 1.58383 35.8876 1.51473C35.8404 1.43576 35.7649 1.39628 35.6611 1.39628H33.9192C33.8248 1.39628 33.7351 1.43082 33.6501 1.49992C33.5652 1.56902 33.5132 1.65786 33.4944 1.76645L32.9987 4.20955H31.8799C31.7761 4.20955 31.6817 4.24904 31.5967 4.328C31.5117 4.39711 31.4598 4.48101 31.4409 4.57972L31.2002 5.85308C31.1719 5.95182 31.186 6.04066 31.2427 6.11961C31.2993 6.18872 31.3796 6.22325 31.4834 6.22325H32.588L32.0357 8.96249C31.9696 9.26851 31.9366 9.55972 31.9366 9.8361Z"
        fill="white"
      />
      <path
        d="M37.3994 11.8033C37.456 11.8724 37.5363 11.907 37.6401 11.907H39.5094C39.6133 11.907 39.7077 11.8724 39.7927 11.8033C39.8777 11.7343 39.9296 11.6454 39.9485 11.5368L40.7132 7.80552C40.7887 7.41071 40.9492 7.1047 41.1947 6.88754C41.4401 6.67037 41.7517 6.56179 42.1293 6.56179H43.5596C43.6635 6.56179 43.7579 6.52721 43.8429 6.45816C43.9278 6.37915 43.975 6.29031 43.9845 6.19162L44.3102 4.57768C44.3291 4.46909 44.3102 4.38025 44.2535 4.31115C44.2064 4.24206 44.1355 4.20751 44.0411 4.20751H43.2197C42.7288 4.20751 42.3134 4.29141 41.9735 4.45922C41.6337 4.61716 41.341 4.84913 41.0956 5.15514L41.2088 4.59248C41.2277 4.4839 41.2088 4.39506 41.1522 4.32596C41.105 4.24699 41.0294 4.20751 40.9256 4.20751H39.1979C39.0941 4.20751 38.9996 4.24699 38.9147 4.32596C38.8297 4.39506 38.7778 4.4839 38.7589 4.59248L37.3569 11.5368C37.338 11.6355 37.3522 11.7244 37.3994 11.8033Z"
        fill="white"
      />
      <path
        d="M45.7371 3.04267C45.7937 3.11177 45.874 3.14632 45.9778 3.14632H47.748C47.8518 3.14632 47.9463 3.11177 48.0312 3.04267C48.1162 2.9637 48.1634 2.86992 48.1729 2.76134L48.4702 1.38432C48.4891 1.27573 48.4703 1.18689 48.4136 1.11779C48.357 1.03883 48.2767 0.999341 48.1729 0.999341H46.4027C46.3083 0.999341 46.2139 1.03883 46.1195 1.11779C46.0345 1.18689 45.9826 1.27573 45.9637 1.38432L45.6804 2.76134C45.6616 2.86992 45.6804 2.9637 45.7371 3.04267Z"
        fill="white"
      />
      <path
        d="M44.1939 11.907C44.0901 11.907 44.0098 11.8724 43.9532 11.8033C43.906 11.7244 43.8918 11.6355 43.9107 11.5368L45.3127 4.57768C45.3316 4.47896 45.3835 4.39506 45.4684 4.32596C45.5534 4.24699 45.6478 4.20751 45.7517 4.20751H47.5219C47.6257 4.20751 47.7013 4.24206 47.7484 4.31115C47.8051 4.38025 47.824 4.46909 47.8051 4.57768L46.389 11.5368C46.3701 11.6454 46.3182 11.7343 46.2332 11.8033C46.1576 11.8724 46.068 11.907 45.9641 11.907H44.1939Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M50.3802 7.62745L49.0058 4.73975C48.9869 4.68053 48.9774 4.64104 48.9774 4.6213C48.9774 4.52258 49.0105 4.43374 49.0766 4.35478C49.1521 4.26593 49.2418 4.22152 49.3456 4.22152H51.1017C51.2813 4.22152 51.4085 4.31036 51.4842 4.48804L52.3723 6.2713C53.1236 6.44157 53.6873 7.14107 53.6873 7.97815C53.6873 8.94265 52.9395 9.72452 52.017 9.72452C51.0946 9.72452 50.3465 8.94265 50.3465 7.97815C50.3465 7.85804 50.3582 7.74077 50.3802 7.62745ZM52.6539 7.97815C52.6539 8.34589 52.3687 8.64401 52.017 8.64401C51.6653 8.64401 51.3802 8.34589 51.3802 7.97815C51.3802 7.61034 51.6653 7.31223 52.017 7.31223C52.3687 7.31223 52.6539 7.61034 52.6539 7.97815Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M51.0845 9.8396C51.1108 9.85449 51.138 9.86844 51.1663 9.88144L50.915 10.5923C50.3712 12.0306 49.5918 13.7783 48.6964 14.8496C48.2701 15.3464 47.7332 15.7255 47.1332 15.9535C46.9523 15.9843 46.7694 15.9998 46.5861 15.9996H44.3975C44.3428 16.0025 44.2886 15.9876 44.2423 15.957C44.2167 15.9346 44.1931 15.9096 44.1721 15.8824C44.162 15.8671 44.1533 15.8508 44.1461 15.8338C44.1447 15.8299 44.1424 15.8263 44.1394 15.8235C44.1377 15.8218 44.1359 15.8205 44.1339 15.8193C44.1323 15.8185 44.1307 15.8178 44.1291 15.8173C44.0532 15.6692 44.0781 15.4927 44.2423 15.2961L46.4762 12.623L46.526 12.5637L50.1226 8.26792V8.29039C50.1296 8.50882 50.1749 8.72398 50.2563 8.92525C50.3546 9.16552 50.5027 9.37995 50.6899 9.55296C50.8085 9.6668 50.9412 9.76323 51.0845 9.8396ZM49.5132 12.5969C49.4461 12.8723 49.359 13.142 49.2526 13.4035C49.0395 13.9208 48.7506 14.4004 48.3962 14.8247C48.6333 14.3335 48.8366 13.8254 49.0046 13.304C49.0669 13.1014 49.1178 12.8953 49.1632 12.6869L48.1595 13.8464L49.2481 12.2416C49.2775 12.0694 49.3025 11.8965 49.3229 11.7228L48.403 12.7959L48.1436 13.1062L48.3566 12.7947L49.3625 11.3296L49.3704 11.3367L49.4124 10.8985C49.4124 10.8819 49.4149 10.8653 49.4174 10.849C49.419 10.8385 49.4206 10.8281 49.4214 10.8179L48.0531 12.4453L49.4702 10.4199C49.4848 10.3015 49.5019 10.1831 49.5234 10.0647C49.5347 9.9995 49.5461 9.93434 49.5585 9.86923L48.0428 11.692L48.6092 10.8949L49.6831 9.37295C49.7721 9.06294 49.8968 8.76546 50.0547 8.48703C49.9764 8.78214 49.9156 9.08194 49.8723 9.3848L49.8652 9.43101C49.8604 9.4618 49.8557 9.49259 49.8519 9.52338C49.8258 9.71523 49.8043 9.90829 49.7896 10.1013C49.7844 10.1795 49.779 10.2575 49.7735 10.3358C49.7598 10.5318 49.7461 10.729 49.7363 10.9304C49.7228 11.2123 49.6956 11.4859 49.6638 11.7595C49.6519 11.8456 49.639 11.9328 49.626 12.02L49.6242 12.0317C49.5925 12.2224 49.5574 12.4109 49.5132 12.5969Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M53.7204 9.06645C53.3702 9.66505 52.7384 10.0645 52.0183 10.0645C51.8503 10.0645 51.6873 10.0428 51.5314 10.002L52.3529 11.6512C52.4281 11.8288 52.5557 11.9177 52.7349 11.9177H54.4909C54.5949 11.9177 54.6847 11.8732 54.7599 11.7844C54.826 11.7054 54.8593 11.6166 54.8593 11.5179C54.8593 11.4982 54.8497 11.4586 54.8311 11.3994L53.7204 9.06645Z"
        fill="white"
      />
    </svg>
  )
}
