import React from 'react'

import { ERoutes } from 'shared/types/routes'
import { ETurboGamesIds } from 'pages/page-games-turbo/TurboGames/types'

import {
  AviatorBackground,
  AviatorLogo,
  AviatrixBackground,
  AviatrixLogo,
  BetGamesBackground,
  BetGamesLogo,
  FastGamesBackground,
  FastGamesLogo,
  LiveGamesBackground,
  LiveGamesLogo,
  SportGamesBackground,
  SportGamesLogo,
  TwainsportBackground,
  TwainsportLogo
} from './SvgImages'
import * as aviatorMainCompressedImg from './JsonImages/aviator_small-compressed.json'
import * as betGamesMainCompressedImg from './JsonImages/betgames_small-compressed.json'
import * as twainsportMainCompressedImg from './JsonImages/twainsport_small-compressed.json'
import * as sportGamesMainCompressedImg from './JsonImages/sport_games_small-compressed.json'
import * as sportGamesAdditionalCompressedImg from './JsonImages/bgsportgames-compressed.json'
import * as liveGamesMainCompressedImg from './JsonImages/live_games_small-compressed.json'
import * as fastGamesMainCompressedImg from './JsonImages/fast_game_small-compressed.json'
import * as aviatrixMainCompressedImg from './JsonImages/aviatrix_small-compressed.json'
import { ISponsorsItemProps } from './SponsorsLowerResolutionItems.types'

export const getLottieDefaultImgOptions = (animationData) => ({
  loop: false,
  autoplay: false,
  animationData
})

export const BANNER_HIGHT = '108px'

export const SPONSORS_LIST_LOWER_RESOLUTION_COMPRESSED: Array<ISponsorsItemProps> =
  [
    {
      url: `${ERoutes.TurboGames}/${ETurboGamesIds.AVIATOR}`,
      name: 'Aviator',
      backgroundImg: <AviatorBackground />,
      mainImg: aviatorMainCompressedImg,
      logoImg: <AviatorLogo />
    },
    {
      url: ERoutes.BetGames,
      name: 'BetGames',
      backgroundImg: <BetGamesBackground />,
      mainImg: betGamesMainCompressedImg,
      logoImg: <BetGamesLogo />,
      itemStyles: {
        bottomPosition: '-3px'
      }
    },
    {
      url: ERoutes.TwainGames,
      name: 'TwainGames',
      backgroundImg: <TwainsportBackground />,
      mainImg: twainsportMainCompressedImg,
      logoImg: <TwainsportLogo />,
      itemStyles: {
        bottomPosition: '-5px'
      }
    },
    {
      url: ERoutes.SportGamesLobby,
      name: 'SportGamesLobby',
      backgroundImg: <SportGamesBackground />,
      mainImg: sportGamesMainCompressedImg,
      logoImg: <SportGamesLogo />,
      additionalImg: sportGamesAdditionalCompressedImg,
      itemStyles: {
        bottomPosition: '-5px'
      }
    },
    {
      url: ERoutes.LiveGames,
      name: 'LiveGames',
      backgroundImg: <LiveGamesBackground />,
      mainImg: liveGamesMainCompressedImg,
      logoImg: <LiveGamesLogo />,
      itemStyles: {
        bottomPosition: '-5px'
      }
    },
    {
      url: ERoutes.TurboGames,
      name: 'TurboGames',
      backgroundImg: <FastGamesBackground />,
      mainImg: fastGamesMainCompressedImg,
      logoImg: <FastGamesLogo />,
      itemStyles: {
        bottomPosition: '-5px'
      }
    },
    {
      url: ERoutes.SportGamesLobby,
      name: 'Aviatrix',
      backgroundImg: <AviatrixBackground />,
      mainImg: aviatrixMainCompressedImg,
      logoImg: <AviatrixLogo />,
      itemStyles: {
        bottomPosition: '-5px'
      }
    }
  ]
