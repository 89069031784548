import React from 'react'

export const BetGamesBackground = () => (
  <svg
    fill="none"
    height="108"
    viewBox="0 0 101 108"
    width="101"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="url(#paint0_radial_29949_722270)"
      height="108"
      rx="8"
      width="100.429"
      x="0.428711"
    />
    <defs>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(23.6528 47.0571) rotate(31.1842) scale(73.0024 113.037)"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial_29949_722270"
        r="1"
      >
        <stop stopColor="#FFFC97" />
        <stop offset="1" stopColor="#F0A003" />
      </radialGradient>
    </defs>
  </svg>
)

export const BetGamesLogo = () => {
  return (
    <svg
      fill="none"
      height="30"
      id="embeddedLowerSVG"
      viewBox="0 0 50 30"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7527 25.6895C11.6896 25.7137 11.6265 25.7387 11.5642 25.7629C11.2316 25.8911 10.7214 25.9795 10.3638 26.0224C10.3599 26.0224 10.3599 26.0224 10.3599 26.0224C9.4002 26.1538 8.35168 25.7911 8.16083 24.7609C8.14291 24.6577 8.13278 24.5514 8.12889 24.4412H12.1064V23.0375C12.0924 22.6607 12.0285 22.298 11.8906 21.9604C11.3414 20.5496 9.45395 20.3753 8.2286 20.82C6.78357 21.332 6.35124 22.8952 6.45406 24.356C6.45406 24.399 6.45796 24.4381 6.46107 24.4772C6.46497 24.5374 6.46808 24.5944 6.47899 24.6515C6.4899 24.7547 6.50703 24.8539 6.52495 24.9532C6.74463 26.4211 8.06579 27.4481 9.52873 27.4231C10.2485 27.4442 11.0571 27.3356 11.7543 27.1089V25.6895H11.7527ZM8.94683 22.0495C9.97042 21.6728 10.6824 22.309 10.5515 23.3962H8.16784C8.22782 22.8452 8.41556 22.273 8.94683 22.0495Z"
        fill="#0A2B61"
      />
      <path
        d="M5.1608 21.4033C4.39194 20.3692 2.59949 20.3301 1.73559 21.279C1.72234 21.279 1.69586 21.279 1.68262 21.279C1.68262 20.6123 1.68262 18.0423 1.68262 18.0423L0 18.4972C0 18.4972 0 25.0003 0 26.9723C1.88204 27.7273 4.87881 27.7508 5.5612 25.3848C5.90863 24.1991 5.90473 22.4186 5.1608 21.4033ZM3.68462 25.4864C3.34654 25.9984 2.69374 26.0382 2.13365 25.9484C1.97474 25.9202 1.82517 25.8757 1.68417 25.803C1.68417 25.1402 1.68417 23.8052 1.68417 23.1424C1.82439 22.7469 2.06043 22.3928 2.46706 22.267C2.89862 22.1349 3.51012 22.2091 3.75395 22.5866C4.17226 23.194 4.14812 24.808 3.68462 25.4864Z"
        fill="#0A2B61"
      />
      <path
        d="M15.8127 25.7523C14.8148 26.0501 14.564 25.6718 14.5749 24.6659C14.5749 24.0062 14.5749 22.7017 14.5749 22.042C14.8748 22.042 15.3905 22.042 15.8127 22.042V20.6132C15.3905 20.6132 14.874 20.6132 14.5749 20.6132C14.5749 20.1146 14.5749 18.9953 14.5749 18.4966C14.1558 18.61 13.3114 18.839 12.8923 18.9531C12.8923 19.3384 12.8915 24.5901 12.8923 25.4452C12.812 27.375 14.3482 27.6915 15.8127 27.2452V25.7523Z"
        fill="#0A2B61"
      />
      <path
        d="M38.3588 25.3251V27.4221H36.6731V25.4501C36.6372 24.7107 36.786 23.1405 36.5134 22.4722C36.3467 22.0532 35.7663 22.0313 35.387 22.1525C35.0045 22.2838 34.7957 22.6355 34.6789 23.0123V27.4221H32.9962V25.5064C32.9573 24.7248 33.11 23.1647 32.8264 22.4644C32.6566 22.0556 32.1004 22.0306 31.7288 22.1478C31.3674 22.2721 31.1376 22.5988 30.9989 22.9474V27.4214H29.3163V21.0207L30.8821 20.5979V21.6179C31.64 20.3814 33.9458 20.0828 34.4452 21.6999C34.9094 20.8331 35.9011 20.4525 36.8398 20.6268C37.6156 20.6799 38.115 21.281 38.242 22.0235C38.4297 22.838 38.3378 24.4653 38.3588 25.3251Z"
        fill="#0A2B61"
      />
      <path
        d="M20.893 20.6245C20.865 20.6206 20.8361 20.6175 20.8042 20.6175C20.5915 20.5925 20.3757 20.5855 20.1592 20.5925C18.771 20.6105 17.4499 21.1537 17.0245 22.6286C16.8617 23.1898 16.7768 23.8831 16.8088 24.5654C16.8127 24.6155 16.8158 24.6647 16.8197 24.7186C16.8236 24.7827 16.8306 24.8499 16.8376 24.914C16.8446 24.9852 16.8555 25.0594 16.8656 25.1305C16.9365 25.5745 17.0643 25.9974 17.2621 26.353C17.266 26.36 17.2691 26.3639 17.273 26.371C17.319 26.453 17.3689 26.5343 17.4257 26.6094C17.9998 27.3808 19.1403 27.5973 20.0392 27.2636C20.0571 27.2597 20.0782 27.2526 20.0961 27.2456C20.3828 27.1323 20.6414 26.9611 20.8502 26.7336H20.9031C20.9031 26.7336 20.9031 26.7336 20.9031 26.7375V27.5621C20.8603 27.8326 20.7652 28.0522 20.6235 28.2265C20.4108 28.479 20.0104 28.6064 19.423 28.6064C19.0367 28.6064 18.711 28.5673 18.4454 28.493C18.1837 28.4149 17.9546 28.3375 17.7568 28.2593V29.7405C17.8884 29.7843 18.0396 29.8273 18.2117 29.8687C18.5693 29.9578 19.0616 30 19.6817 30C20.5993 30 21.3105 29.7765 21.8208 29.3286C21.8987 29.2614 21.9735 29.1863 22.0366 29.1051C22.4011 28.6642 22.5857 28.0428 22.5857 27.2432V21.0419C22.0794 20.8379 21.4952 20.6886 20.893 20.6245ZM20.9039 25.0281C20.9039 25.0321 20.9 25.0352 20.9 25.0391C20.7582 25.4338 20.5245 25.7785 20.121 25.9059C19.6887 26.0372 19.0764 25.963 18.8357 25.5862C18.4143 24.9789 18.4392 23.3617 18.9027 22.6825C19.2213 22.1995 19.8203 22.1385 20.3547 22.2097C20.3937 22.2167 20.4287 22.2206 20.4646 22.2237C20.4996 22.2308 20.5354 22.2378 20.5705 22.2448C20.6873 22.273 20.7933 22.312 20.9 22.366H20.9039V25.0281Z"
        fill="#0A2B61"
      />
      <path
        d="M48.5171 23.432C47.9655 23.2006 46.4979 22.9028 47.2278 22.0923C47.8783 21.7242 48.8481 22.1079 49.5001 22.3518V20.9629C48.179 20.4454 46.1365 20.2641 45.4985 21.7242C45.1869 22.5237 45.3606 23.6196 46.1201 24.1222C46.7005 24.5677 47.4981 24.631 48.0894 25.0452C48.2335 25.1468 48.3098 25.2836 48.3098 25.4587C48.2452 26.6304 46.1668 25.8823 45.5756 25.5275V27.0266C46.4901 27.4378 47.6594 27.5409 48.5802 27.2814C50.5183 26.7281 50.4482 23.9596 48.5171 23.432Z"
        fill="#0A2B61"
      />
      <path
        d="M44.4215 25.685C44.3584 25.7092 44.2953 25.7343 44.233 25.7585C43.9003 25.8867 43.3901 25.975 43.0325 26.018C43.0286 26.018 43.0286 26.018 43.0286 26.018C42.0689 26.1493 41.0204 25.7866 40.8296 24.7565C40.8116 24.6533 40.8015 24.547 40.7976 24.4368H44.7751V23.033C44.7611 22.6563 44.6972 22.2936 44.5594 21.9559C44.0102 20.5451 42.1227 20.3708 40.8973 20.8156C39.4523 21.3275 39.02 22.8907 39.1228 24.3516C39.1228 24.3946 39.1267 24.4337 39.1298 24.4727C39.1337 24.5329 39.1368 24.59 39.1477 24.647C39.1586 24.7502 39.1758 24.8495 39.1937 24.9487C39.4133 26.4166 40.7345 27.4436 42.1975 27.4186C42.9172 27.4397 43.7258 27.3311 44.423 27.1044V25.685H44.4215ZM41.6156 22.045C42.6391 21.6683 43.3511 22.3045 43.2203 23.3918H40.8366C40.8965 22.8407 41.0843 22.2686 41.6156 22.045Z"
        fill="#0A2B61"
      />
      <path
        d="M28.3052 23.1875C28.2911 22.6192 28.2662 22.1643 28.2202 21.9611C28.0824 21.3319 27.6851 20.9622 27.1577 20.7668C26.1341 20.383 24.6322 20.6605 23.6375 20.9943V22.4301C24.3105 22.1385 26.0282 21.6805 26.5415 22.3379C26.5485 22.3488 26.5524 22.359 26.5555 22.3699C26.6833 22.6114 26.6934 22.8889 26.6693 23.1875C26.3468 23.1945 25.8404 23.2305 25.3302 23.3508C25.1635 23.3899 25.0046 23.4376 24.8628 23.4876C23.5237 23.9675 23.262 24.8898 23.2371 25.4299C23.1942 26.3116 23.6156 27.0041 24.3635 27.2886C24.5691 27.3668 24.7919 27.4097 25.0225 27.4199H25.0264C25.0614 27.4238 25.0973 27.4238 25.1323 27.4238C25.6706 27.4238 26.2478 27.2534 26.7651 26.9509V27.4238C27.1445 27.4238 28.2771 27.4238 28.2771 27.4238C28.2771 27.4238 28.2981 26.564 28.309 25.5151C28.3192 24.7515 28.3192 23.8839 28.3052 23.1875ZM26.7425 25.1993C26.5205 25.5917 26.2237 25.9177 25.7594 25.938C25.3442 25.9911 24.7475 25.7621 24.7865 25.165C24.8309 24.4842 26.1559 24.4756 26.7425 24.5068V25.1993Z"
        fill="#0A2B61"
      />
      <path
        d="M32.0974 7.12118C32.0974 11.0546 28.9195 14.2424 25.0001 14.2424C21.0807 14.2424 17.9029 11.0538 17.9029 7.12118C17.9029 3.18859 21.0807 0 25.0001 0C28.9195 0 32.0974 3.18859 32.0974 7.12118ZM25.0001 3.95917C23.2601 3.95917 21.8487 5.37449 21.8487 7.12118C21.8487 8.86709 23.2593 10.2832 25.0001 10.2832C26.7409 10.2832 28.1515 8.86788 28.1515 7.12118C28.1515 5.37527 26.7402 3.95917 25.0001 3.95917Z"
        fill="url(#paint0_linear_29807_674808)"
      />
      <path
        d="M15.0863 13.6284C14.1174 14.0613 12.9826 13.6237 12.551 12.6515L8.33958 3.16074C7.90807 2.18853 8.34425 1.04986 9.31319 0.616898C10.2821 0.183937 11.417 0.621587 11.8485 1.59379L16.06 11.0845C16.4915 12.0567 16.0553 13.1954 15.0863 13.6284Z"
        fill="url(#paint1_linear_29807_674808)"
      />
      <path
        d="M40.6873 13.6284C39.7184 14.0613 38.5835 13.6237 38.152 12.6515L33.9406 3.16074C33.5091 2.18853 33.9452 1.04986 34.9142 0.616898C35.8831 0.183937 37.018 0.621587 37.4495 1.59379L41.6609 11.0845C42.0924 12.0567 41.6563 13.1954 40.6873 13.6284Z"
        fill="url(#paint2_linear_29807_674808)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_29807_674808"
          x1="25"
          x2="25"
          y1="30.4745"
          y2="2.02284"
        >
          <stop stopColor="#FF8C1A" />
          <stop offset="1" stopColor="#F7AD1C" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_29807_674808"
          x1="25.0007"
          x2="25.0007"
          y1="31.5134"
          y2="1.14996"
        >
          <stop stopColor="#FF8C1A" />
          <stop offset="1" stopColor="#F7AD1C" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_29807_674808"
          x1="37.8"
          x2="37.8"
          y1="14.4692"
          y2="0.961892"
        >
          <stop stopColor="#FF8C1A" />
          <stop offset="1" stopColor="#F7AD1C" />
        </linearGradient>
      </defs>
    </svg>
  )
}
