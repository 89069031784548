import styled, { css } from 'styled-components'

import { RIGHT_CONTAINER_WIDTH } from 'widgets/right-container'

export const StyledSponsorWrapper = styled.div`
  min-width: calc(100% - ${RIGHT_CONTAINER_WIDTH}px);
  padding: 16px;
`

export const StyledSponsorTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  padding: 6px 0;
`

export const StyledSponsorTitle = styled.div`
  line-height: 28px;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.xl};
      font-weight: ${theme.fonts.weight.bold};
    `}
`
