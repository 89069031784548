import React from 'react'

export const LiveGamesBackground = () => (
  <svg
    fill="none"
    height="72"
    viewBox="0 0 228 72"
    width="228"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="url(#paint0_linear_29821_684936)"
      height="72"
      rx="8"
      width="228"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_29821_684936"
        x1="228"
        x2="185.128"
        y1="68.9434"
        y2="-61.0535"
      >
        <stop stopColor="#7F08F6" />
        <stop offset="0.90625" stopColor="#210A34" />
      </linearGradient>
    </defs>
  </svg>
)

export const LiveGamesLogo = () => {
  return (
    <svg
      fill="none"
      height="36"
      id="embeddedSVG"
      viewBox="0 0 40 36"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 2.5C4 1.39543 4.89543 0.5 6 0.5H29C30.1046 0.5 31 1.39543 31 2.5V11.5C31 12.6046 30.1046 13.5 29 13.5H6C4.89543 13.5 4 12.6046 4 11.5V2.5Z"
        fill="#7F00FE"
      />
      <path
        d="M0 11.5C0 10.3954 0.895431 9.5 2 9.5H38C39.1046 9.5 40 10.3954 40 11.5V20.5C40 21.6046 39.1046 22.5 38 22.5H2C0.895431 22.5 0 21.6046 0 20.5V11.5Z"
        fill="#7F00FE"
      />
      <path
        d="M6.85827 11.8121H10.9921V10.1805H8.92839V3.5H6.85827V11.8121Z"
        fill="white"
      />
      <path d="M13.539 3.5H11.4688V11.8121H13.539V3.5Z" fill="white" />
      <path
        d="M16.2872 3.5H13.9703L16.309 11.8121H19.065L21.4036 3.5H19.0867L17.7225 9.58798H17.6514L16.2872 3.5Z"
        fill="white"
      />
      <path
        d="M21.8297 11.8121H27.8017V10.1805H23.8999V8.47185H27.4965V6.83621H23.8999V5.13158H27.8017V3.5H21.8297V11.8121Z"
        fill="white"
      />
      <path
        d="M8.07969 15.3086H9.94273C9.7568 13.8032 8.40321 12.7369 6.64057 12.7369C4.60646 12.7369 3 14.1574 3 16.6295C3 19.0204 4.48003 20.5 6.66288 20.5C8.62261 20.5 10.0245 19.2935 10.0245 17.2641V16.2642H6.74097V17.5962H8.25074C8.23215 18.3932 7.67435 18.8987 6.67775 18.8987C5.53612 18.8987 4.87792 18.0611 4.87792 16.6074C4.87792 15.1647 5.57331 14.3382 6.67775 14.3382C7.41405 14.3382 7.91607 14.6961 8.07969 15.3086Z"
        fill="white"
      />
      <path
        d="M11.4744 20.3967L11.9765 18.8101H14.6056L15.1076 20.3967H17.0859L14.5163 12.8402H12.0657L9.49612 20.3967H11.4744ZM12.4153 17.4228L13.2594 14.7441H13.3189L14.1668 17.4228H12.4153Z"
        fill="white"
      />
      <path
        d="M16.8587 12.8402V20.3967H18.6511V15.7292H18.7143L20.5439 20.3487H21.6892L23.5188 15.7551H23.582V20.3967H25.3744V12.8402H23.0949L21.1612 17.5187H21.0719L19.1382 12.8402H16.8587Z"
        fill="white"
      />
      <path
        d="M26.0361 20.3967H31.3464V18.9134H27.8769V17.3601H31.0749V15.8731H27.8769V14.3235H31.3464V12.8402H26.0361V20.3967Z"
        fill="white"
      />
      <path
        d="M36.1332 15.1057H37.8959C37.881 13.6925 36.7468 12.7369 34.9767 12.7369C33.2364 12.7369 31.9832 13.6778 31.9944 15.0835C31.9906 16.231 32.8013 16.8767 34.1177 17.1756L34.9135 17.3601C35.7502 17.5519 36.1258 17.777 36.1332 18.2013C36.1258 18.6625 35.6907 18.9835 34.9507 18.9835C34.1363 18.9835 33.6008 18.6072 33.5599 17.8803H31.7973C31.8196 19.6477 33.0579 20.4926 34.973 20.4926C36.8695 20.4926 37.9926 19.6403 38 18.205C37.9926 16.9985 37.1707 16.2606 35.6684 15.9322L35.0139 15.7846C34.3223 15.637 33.8834 15.4082 33.8983 14.9581C33.902 14.5449 34.259 14.246 34.973 14.246C35.6907 14.246 36.0849 14.567 36.1332 15.1057Z"
        fill="white"
      />
      <path
        d="M17.7518 35.5C21.3207 35.5 23.821 34.3334 23.821 31.4168C23.8353 29.7466 22.6385 28.5534 20.2878 28.3138V28.2074C22.0259 28.0344 23.2939 26.9897 23.2796 25.466C23.2939 23.1704 21.0144 21.5003 17.7803 21.5003C14.4751 21.5003 16.1808 21.5 14.1748 21.5L14.9151 24.641C14.9151 24.641 16.6904 24.6409 17.7803 24.6409C18.7776 24.6409 19.4615 25.0076 19.4615 25.7055C19.4757 26.5306 18.6494 27.1161 17.4669 27.1161H15.3497L15.9852 29.6712H17.4669C18.7491 29.6712 19.6467 30.2568 19.6324 31.0818C19.6467 31.9269 18.8773 32.5191 17.7803 32.5191C16.6406 32.5191 15.3496 32.5833 14.1748 32.5833L13 35.5C13.6499 35.5 14.297 35.5 17.7518 35.5Z"
        fill="white"
      />
      <path
        d="M23.5734 35.3137H27.6481L35 21.6866H23.2518L24.3384 24.6409H29.3007V24.7208L23.5734 35.3137Z"
        fill="white"
      />
    </svg>
  )
}
