export enum ETurboGamesIds {
  AVIATOR = 'aviator',
  DICE = 'dice',
  GOAL = 'goal',
  PLINKO = 'plinko',
  MINES = 'mines',
  HI_LO = 'hi-lo',
  KENO = 'keno',
  MINE_ROULETTE = 'mini-roulette'
}

export type TTurboGamesListItem = {
  gameId: ETurboGamesIds
  iconSrc: string
}

export interface TurboGamesCardProps {
  data: TTurboGamesListItem
}
