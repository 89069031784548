import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconServicesSponsors: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7752 14.1284L14.7687 5.01606C14.2654 3.47294 12.6098 2.63487 11.0734 3.15368L2.01406 6.1601C0.477681 6.6656 -0.356733 8.32844 0.146564 9.87156L3.1531 18.9839C3.6564 20.5271 5.31199 21.3651 6.84837 20.8463L15.921 17.8266C17.4573 17.3211 18.2918 15.6583 17.7752 14.1284ZM3.04715 9.77844C3.04715 8.92707 3.73587 8.24863 4.57028 8.24863C5.41794 8.24863 6.09342 8.92707 6.09342 9.77844C6.09342 10.6298 5.4047 11.3083 4.57028 11.3083C3.72263 11.3083 3.04715 10.6298 3.04715 9.77844ZM6.75565 17.9463C5.908 17.9463 5.23252 17.2546 5.23252 16.4165C5.23252 15.5784 5.908 14.8734 6.75565 14.8734C7.60331 14.8734 8.27879 15.5651 8.27879 16.4032C8.27879 17.2413 7.60331 17.9463 6.75565 17.9463ZM8.92778 13.5564C8.08012 13.5564 7.40464 12.8647 7.40464 12.0266C7.40464 11.1885 8.08012 10.4968 8.92778 10.4968C9.77544 10.4968 10.4509 11.1885 10.4509 12.0266C10.4509 12.8647 9.7622 13.5564 8.92778 13.5564ZM9.64299 7.58349C9.64299 6.73211 10.3317 6.05367 11.1794 6.05367C12.027 6.05367 12.7025 6.74542 12.7025 7.58349C12.7025 8.42156 12.0138 9.11331 11.1794 9.11331C10.3317 9.11331 9.64299 8.43487 9.64299 7.58349ZM13.3515 15.7514C12.5038 15.7514 11.8284 15.0596 11.8284 14.2216C11.8284 13.3835 12.5171 12.6917 13.3515 12.6917C14.1992 12.6917 14.8746 13.3835 14.8746 14.2216C14.8746 15.0596 14.1992 15.7514 13.3515 15.7514Z"
        fill="#F54D3D"
      />
      <path
        d="M21.6294 5.61468L16.3183 4.59037L19.2851 13.6229C20.0666 15.9908 18.7818 18.5716 16.411 19.3564L14.4773 19.995L18.7421 20.8064C20.3314 21.1124 21.8546 20.0615 22.1592 18.4651L23.9472 9.03349C24.2519 7.45046 23.2055 5.90734 21.6294 5.61468Z"
        fill="#F54D3D"
      />
    </svg>
  </Icon>
)
