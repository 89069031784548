import React from 'react'

import { ERoutes } from 'shared/types/routes'
import { ETurboGamesIds } from 'pages/page-games-turbo/TurboGames/types'

import { ESponsorsName } from '../constants'

import {
  AviatorBackground,
  AviatorLogo,
  AviatrixBackground,
  AviatrixLogo,
  BetGamesBackground,
  BetGamesLogo,
  FastGamesBackground,
  FastGamesLogo,
  LiveGamesBackground,
  LiveGamesLogo,
  SportGamesBackground,
  SportGamesLogo,
  TwainsportBackground,
  TwainsportLogo
} from './SvgImages'
import * as aviatorMainCompressedImg from './JsonImages/aviator-compessed.json'
import * as aviatorMainMiddleCompressedImg from './JsonImages/aviator_middle-compressed.json'
import * as aviatrixMainBigCompressedImg from './JsonImages/aviatrix-compressed.json'
import * as aviatrixMainMiddleCompressedImg from './JsonImages/aviatrix_middle-compressed.json'
import * as twainsportMainCompressedImg from './JsonImages/twainsport-compressed.json'
import * as sportGamesMainCompressedImg from './JsonImages/sportgames-compressed.json'
import * as sportGamesAdditionalCompressedImg from './JsonImages/bgsportgames-compressed.json'
import * as fastGamesMainCompressedImg from './JsonImages/fastgames-compressed.json'
import * as fastGamesMainMiddleCompressedImg from './JsonImages/fastgames_middle-compressed.json'
import * as liveGamesMainCompressedImg from './JsonImages/livegames-compressed.json'
import * as betGamesMainCompressedImg from './JsonImages/betgames-compressed.json'

export const getLottieDefaultImgOptions = (animationData) => ({
  loop: false,
  autoplay: false,
  animationData
})

export const BANNER_HIGHT = '72px'

export const SPONSORS_LIST_HIGH_RESOLUTION_COMPRESSED = (
  sponsorsWrapperWidth: number
) => {
  const isWideScreen = sponsorsWrapperWidth > 1150

  return [
    {
      url: `${ERoutes.TurboGames}/${ETurboGamesIds.AVIATOR}`,
      name: ESponsorsName.AVIATOR,
      backgroundImg: <AviatorBackground />,
      mainImg: isWideScreen
        ? aviatorMainCompressedImg
        : aviatorMainMiddleCompressedImg,
      logoImg: <AviatorLogo />,
      itemStyles: {
        topPosition: isWideScreen ? '7px' : '10px'
      }
    },
    {
      url: ERoutes.BetGames,
      name: ESponsorsName.BET_GAMES,
      backgroundImg: <BetGamesBackground />,
      mainImg: betGamesMainCompressedImg,
      logoImg: <BetGamesLogo />,
      itemStyles: {
        topPosition: isWideScreen ? '7px' : '10px',
        rightPosition: '-10px'
      }
    },
    {
      url: ERoutes.TwainGames,
      name: ESponsorsName.TWAIN_SPORT,
      backgroundImg: <TwainsportBackground />,
      mainImg: twainsportMainCompressedImg,
      logoImg: <TwainsportLogo />,
      itemStyles: {
        topPosition: isWideScreen ? '10px' : '15px',
        rightPosition: '-10px'
      }
    },
    {
      url: ERoutes.SportGamesLobby,
      name: ESponsorsName.SPORT_GAMES,
      backgroundImg: <SportGamesBackground />,
      mainImg: sportGamesMainCompressedImg,
      logoImg: <SportGamesLogo />,
      additionalImg: sportGamesAdditionalCompressedImg,
      itemStyles: {
        topPosition: isWideScreen ? '10px' : '12px',
        topAdditionalImgPosition: isWideScreen ? '0' : '-10px'
      }
    },
    {
      url: ERoutes.LiveGames,
      name: ESponsorsName.LIVE_GAMES_37,
      backgroundImg: <LiveGamesBackground />,
      mainImg: liveGamesMainCompressedImg,
      logoImg: <LiveGamesLogo />,
      itemStyles: {
        topPosition: isWideScreen ? '10px' : '12px'
      }
    },
    {
      url: ERoutes.TurboGames,
      name: ESponsorsName.FAST_GAMES,
      backgroundImg: <FastGamesBackground />,
      mainImg: isWideScreen
        ? fastGamesMainCompressedImg
        : fastGamesMainMiddleCompressedImg,

      logoImg: <FastGamesLogo />
    },
    {
      url: ERoutes.SportGamesLobby,
      name: ESponsorsName.AVIATRIX,
      backgroundImg: <AviatrixBackground />,
      mainImg: isWideScreen
        ? aviatrixMainBigCompressedImg
        : aviatrixMainMiddleCompressedImg,
      logoImg: <AviatrixLogo />,
      itemStyles: {
        topPosition: isWideScreen ? '7px' : '10px'
      }
    }
  ]
}
