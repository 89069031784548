import React from 'react'

export const AviatorBackground = () => (
  <svg
    fill="none"
    height="108"
    viewBox="0 0 101 108"
    width="101"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_29949_722259)">
      <rect
        fill="url(#paint0_linear_29949_722259)"
        height="108"
        rx="8"
        width="100.429"
      />
      <path
        d="M101.297 -0.394581L24.6792 51.0326L126.586 -0.394581H142.611C131.677 10.522 107.407 34.3507 97.7918 42.3335C85.7733 52.3119 136.602 18.0271 156.883 12.3983C177.164 6.7694 191.937 6.00183 194.941 15.2127C197.946 24.4235 166.648 46.4272 159.136 49.7533C151.625 53.0795 98.7933 86.3408 94.036 91.2021C89.2787 96.0634 80.7655 108.089 88.2771 108.089C94.2864 108.089 61.8197 109.368 42.4565 110.647C63.7393 95.0399 115.169 60.8575 126.586 54.1029C132.379 50.6759 136.569 48.3335 139.444 46.8015L14.9141 108.089H6.15062C15.4984 98.0249 37.549 75.0831 50.9697 63.8254C64.3903 52.5677 71.9186 46.1713 74.0051 44.3803C54.642 56.0644 14.6137 79.9444 9.40563 81.9913C4.19761 84.0381 -1.77826 85.9144 -4.11519 86.5967V46.683L6.15062 41.0542L-4.11519 44.3803C-4.78289 28.7731 -5.71766 -2.49261 -4.11519 -2.69729C-2.51273 -2.90198 70.3328 -4.31772 106.555 -5L101.297 -0.394581Z"
        fill="url(#paint1_linear_29949_722259)"
      />
      <path
        d="M165.897 84.5498C148.62 87.1084 145.866 70.7336 149.872 60.2434C153.01 52.0256 199.142 32.8328 223.235 23.7107L225.989 23.1442L228.628 55.2065C230.271 54.238 231.741 53.3504 233 52.5677C231.767 53.7625 230.334 55.1102 228.741 56.58L230.496 77.8976C228.994 78.4093 222.325 78.8821 210.966 87.3642C197.946 97.0868 191.937 104.251 185.928 110.391C180.836 112.012 171.205 113.82 173.408 108.089C175.426 102.841 211.462 72.5264 228.741 56.58L228.628 55.2065C212.192 64.8971 178.462 82.6889 165.897 84.5498Z"
        fill="url(#paint2_linear_29949_722259)"
      />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_29949_722259"
        x1="0"
        x2="117.915"
        y1="0"
        y2="24.7744"
      >
        <stop stopColor="#FF4545" />
        <stop offset="1" stopColor="#D90404" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_29949_722259"
        x1="-25.5502"
        x2="137.95"
        y1="1.3375"
        y2="68.9389"
      >
        <stop stopColor="#EE010D" />
        <stop offset="0.770905" stopColor="#540000" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_29949_722259"
        x1="-25.5502"
        x2="137.95"
        y1="1.3375"
        y2="68.9389"
      >
        <stop stopColor="#EE010D" />
        <stop offset="0.770905" stopColor="#540000" />
      </linearGradient>
      <clipPath id="clip0_29949_722259">
        <rect fill="white" height="108" rx="8" width="100.429" />
      </clipPath>
    </defs>
  </svg>
)

export const AviatorLogo = () => {
  return (
    <svg
      fill="none"
      height="14"
      id="embeddedLowerSVG"
      viewBox="0 0 58 14"
      width="58"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M11.6163 1.16933C11.0447 0.72431 10.276 0.5 9.30588 0.5C8.2474 0.5 7.31758 0.751047 6.50855 1.24941C5.69952 1.74765 5.18823 2.40953 4.97088 3.23491L3.93239 7.1302C3.45809 7.1302 3.00258 7.13129 2.58433 7.13229C2.22917 7.13314 1.9 7.13392 1.6099 7.13392C0.716538 7.13392 0 7.82254 0 8.66707H3.52189L2.23789 13.4979H4.71734L6.00543 8.66707H8.26345L7.46244 11.6645C7.43837 11.7215 7.42218 11.7976 7.40613 11.8928C7.31758 12.3987 7.42218 12.7941 7.724 13.0758C8.02188 13.3571 8.47672 13.4979 9.08444 13.4979H10.0748L10.3927 12.3149C10.0344 12.2922 9.88545 12.1095 9.94598 11.7709L12.212 3.23491C12.216 3.20843 12.2241 3.16267 12.2443 3.09416C12.2644 3.02191 12.2724 2.97615 12.2764 2.94967C12.4052 2.2117 12.1837 1.61821 11.6163 1.16933ZM6.39579 7.15681L6.39988 7.1302L7.54691 2.90018C7.63151 2.54643 7.8086 2.25348 8.07819 2.02518C8.34805 1.79714 8.65381 1.68287 8.98787 1.68287C9.30194 1.68287 9.53126 1.78185 9.68428 1.9834C9.83322 2.18123 9.88545 2.43601 9.83322 2.74053L9.80506 2.90018L8.6579 7.1302L8.64987 7.15681H6.39579Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M19.1309 4.52232L17.5246 10.5549C17.3908 11.0372 17.0896 11.4523 16.619 11.7982C16.1484 12.1441 15.6507 12.318 15.1298 12.318L17.2088 4.52232H14.6384L14.3727 5.70429L12.3126 13.5H14.8119C16.0313 13.5 17.19 13.0731 18.2901 12.2212C19.3902 11.3694 20.0888 10.3671 20.39 9.21471L21.6322 4.52232H19.1309Z"
        fill="white"
      />
      <path
        d="M25.7638 0.5C26.111 0.5 26.3892 0.618518 26.5984 0.853754C26.8054 1.08899 26.8807 1.37166 26.8242 1.69971C26.7679 2.02981 26.5921 2.31441 26.3035 2.55568C26.0127 2.79671 25.6928 2.91741 25.3435 2.91741C24.9962 2.91741 24.7181 2.79876 24.5111 2.56353C24.3019 2.32829 24.2265 2.04176 24.2872 1.69971C24.3438 1.37166 24.5173 1.08899 24.8059 0.853754C25.0966 0.618518 25.4146 0.5 25.7638 0.5Z"
        fill="white"
      />
      <path
        d="M24.2789 11.3633C24.2664 11.3989 24.2581 11.4286 24.2537 11.4522C24.1658 11.9583 24.373 12.2469 24.8707 12.3161L24.5528 13.498H24.1807C23.3209 13.498 22.6747 13.3182 22.2396 12.9605C21.8046 12.6007 21.6393 12.1164 21.7461 11.5056C21.7628 11.4107 21.7796 11.3416 21.8005 11.2921L23.2895 5.72006H21.631L21.9469 4.5381H26.0901L24.3019 11.2921C24.3002 11.3033 24.2944 11.3195 24.2857 11.344L24.2789 11.3633Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M34.335 11.6646C34.3392 11.6409 34.3477 11.6172 34.3581 11.5934C34.3686 11.5698 34.3749 11.5479 34.3789 11.5243L36.2258 4.53891H31.9153C31.018 4.53891 30.1835 4.77813 29.4116 5.25452C28.6378 5.73077 28.1589 6.32773 27.9727 7.04334L26.9312 10.9945C26.9082 11.0538 26.8894 11.1289 26.8746 11.2239C26.7597 11.882 26.9625 12.4256 27.4853 12.8545C28.0082 13.2834 28.6922 13.4988 29.5393 13.4988H29.9305C30.6645 13.4988 31.3463 13.2578 31.978 12.7754C32.2938 13.2578 32.8858 13.4988 33.7559 13.4988H34.6341L34.9501 12.3169C34.469 12.2931 34.264 12.0757 34.335 11.6646ZM32.0072 10.9945C31.9068 11.3582 31.6851 11.6706 31.3422 11.9294C30.9992 12.1885 30.6332 12.3169 30.2482 12.3169C29.5267 12.3169 29.2235 11.9887 29.3364 11.3285C29.3386 11.3187 29.3469 11.289 29.3616 11.2416C29.3761 11.1942 29.3845 11.1586 29.3886 11.1349L30.5871 6.60243C30.7503 6.0155 31.0932 5.72088 31.6162 5.72088H33.4086L32.0072 10.9945Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M41.1806 2.45725L40.6138 4.53659H41.6973L41.3793 5.71856H40.3147L38.8235 11.3084C38.8223 11.317 38.8185 11.3286 38.8133 11.3448L38.8092 11.3576L38.8024 11.3797C38.7899 11.4151 38.7816 11.4448 38.7776 11.4686C38.6896 11.9746 38.903 12.2513 39.4174 12.2967L39.0975 13.4965H38.2189C37.51 13.4965 36.9809 13.3226 36.6294 12.9767C36.2801 12.6289 36.1568 12.1563 36.2614 11.5574C36.2656 11.5319 36.2739 11.4923 36.2906 11.433C36.3075 11.3737 36.3178 11.3322 36.322 11.3084L37.8154 5.71856H36.5855L36.9035 4.53659H38.1312L38.6791 2.45725H41.1806Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M49.2231 5.1833C48.7065 4.75448 48.0269 4.53891 47.1798 4.53891H46.7886C45.8664 4.53891 45.0255 4.78019 44.2642 5.26249C43.5028 5.74479 43.0303 6.33969 42.8462 7.04334L41.7857 10.9945C41.7649 11.0538 41.744 11.129 41.7293 11.2239C41.6122 11.8938 41.815 12.4395 42.34 12.8645C42.863 13.2874 43.5552 13.499 44.4126 13.499H44.785C45.6949 13.499 46.5315 13.2538 47.3011 12.7676C48.0708 12.2793 48.5455 11.6883 48.7295 10.9945L49.7879 7.04334L49.8276 6.81608C49.9406 6.15587 49.7397 5.61238 49.2231 5.1833ZM47.3199 6.84963L47.2864 7.04334L46.2469 10.9945C46.1361 11.4176 45.9541 11.7437 45.7031 11.973C45.4522 12.2022 45.1908 12.3169 44.9168 12.3169C44.6679 12.3169 44.4775 12.2121 44.3458 11.9986C44.214 11.7871 44.1785 11.5184 44.2348 11.1883C44.2391 11.1645 44.2475 11.1329 44.2622 11.0915C44.2747 11.0498 44.2831 11.0183 44.2872 10.9945L45.3267 7.04334C45.4313 6.65591 45.6048 6.34162 45.8454 6.10047C46.0859 5.85945 46.3556 5.73874 46.6547 5.73874C46.9036 5.73874 47.0919 5.83567 47.2195 6.02938C47.3471 6.22503 47.3827 6.4978 47.3199 6.84963Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M54.5469 5.29797C55.281 4.79189 56.1533 4.53891 57.1613 4.53891H58L57.3328 6.99193H55.9691L56.2662 5.8277C55.6575 6.02733 55.1137 6.38905 54.6306 6.91287C54.1474 7.43463 53.8233 7.99599 53.6581 8.59693L52.3592 13.4988H49.8577L52.2463 4.53891H54.7352L54.5469 5.29797Z"
        fill="white"
      />
    </svg>
  )
}
