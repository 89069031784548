import React, { FC } from 'react'
import range from 'lodash/range'

import {
  SponsorsSkeletonWrapper,
  SponsorsSkeletonItem
} from './SponsorsSkeleton.styled'

export const SponsorsSkeleton: FC = () => (
  <SponsorsSkeletonWrapper>
    {range(7).map((item) => (
      <SponsorsSkeletonItem key={item} />
    ))}
  </SponsorsSkeletonWrapper>
)
