import styled, { css, keyframes } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { StyledSponsorsItemsWrapper } from '../SponsorsHighResolution/SponsorsHighResolutionItems.styled'

export const SponsorsSkeletonWrapper = styled(StyledSponsorsItemsWrapper)``

const SkeletonShine = keyframes`
  0%, 27% {
    left: -100%;
  }
  50%, 100% {
    left: 150%;
  }
`

export const SponsorsSkeletonItem = styled.div`
  background: ${(p) => p.theme.colors.custom.primary[4]};
  border-radius: 8px;
  gap: 8px;
  height: 72px;
  overflow: hidden;
  position: relative;
  width: 160px;

  &::before {
    animation: ${SkeletonShine} 3.5s infinite linear;
    content: '';
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${theme.typeLayoutTheme === LayoutTheme.Dark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.4)'},
        rgba(255, 255, 255, 0)
      );
    `};
    width: 70%;
  }
`
