import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useResizeContainer } from 'astra-core'
import loadable from '@loadable/component'

import { IconServicesSponsors } from 'shared/ui/Icon/General/IconServicesSponsors'

import {
  StyledSponsorTitle,
  StyledSponsorTitleWrapper,
  StyledSponsorWrapper
} from './Sponsors.styled'
import { isShowingSmallBanners } from './constants'
import { SPONSORS_LIST_HIGH_RESOLUTION_COMPRESSED } from './SponsorsHighResolution/constants'
import { SponsorsSkeleton } from './SponsorsSkeleton'
import { SPONSORS_LIST_LOWER_RESOLUTION_COMPRESSED } from './SponsorsLowerResolution/constants'

const SponsorsLowerResolutionItems = loadable(
  () => import('./SponsorsLowerResolution'),
  {
    resolveComponent: (c) => c.SponsorsLowerResolutionItems,
    fallback: <></>
  }
)

const SponsorsHighResolutionItems = loadable(
  () => import('./SponsorsHighResolution'),
  {
    resolveComponent: (c) => c.SponsorsHighResolutionItems,
    fallback: <></>
  }
)

export const Sponsors: FC = () => {
  const [t] = useTranslation()
  const sponsorsWrapperRef = useRef(null)
  const [width] = useResizeContainer(sponsorsWrapperRef)
  const [imagesLoaded, setImagesLoaded] = useState(0)

  const shouldShowSmallBanners = width !== 0 && isShowingSmallBanners(width)

  return (
    <StyledSponsorWrapper ref={sponsorsWrapperRef}>
      <StyledSponsorTitleWrapper>
        <IconServicesSponsors size={26} />
        <StyledSponsorTitle>{t('services')}</StyledSponsorTitle>
      </StyledSponsorTitleWrapper>

      {width !== 0 &&
        (shouldShowSmallBanners ? (
          <>
            {imagesLoaded <
              SPONSORS_LIST_LOWER_RESOLUTION_COMPRESSED.length && (
              <SponsorsSkeleton />
            )}
            <SponsorsLowerResolutionItems
              hidden={
                imagesLoaded >= SPONSORS_LIST_LOWER_RESOLUTION_COMPRESSED.length
              }
              setImagesLoaded={setImagesLoaded}
            />
          </>
        ) : (
          <>
            {imagesLoaded <
              SPONSORS_LIST_HIGH_RESOLUTION_COMPRESSED(width).length && (
              <SponsorsSkeleton />
            )}
            <SponsorsHighResolutionItems
              hidden={
                imagesLoaded >=
                SPONSORS_LIST_HIGH_RESOLUTION_COMPRESSED(width).length
              }
              setImagesLoaded={setImagesLoaded}
              sponsorsWrapperWidth={width}
            />
          </>
        ))}
    </StyledSponsorWrapper>
  )
}
