export enum ESponsorsName {
  AVIATOR = 'aviator',
  BET_GAMES = 'betgames',
  TWAIN_SPORT = 'twainsport',
  LIVE_GAMES_37 = 'live_games_37',
  SPORT_GAMES = 'sport_games',
  FAST_GAMES = 'fast_games',
  AVIATRIX = 'aviatrix',
  VSPORT = 'vsport_games'
}

export const MIN_WIDTH_FOR_SHOWING_SMALL_BANNERS = 783

export const isShowingSmallBanners = (width: number) =>
  width < MIN_WIDTH_FOR_SHOWING_SMALL_BANNERS
